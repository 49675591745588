import React from "react";
import { formatNumber } from '../../Utils'

const SubtotalItem = ({ item }) => {
    return (
        <div>
            <div style={{ paddingBottom: "10px" }}>
                <h5 className='mb-0'>{item.name}</h5>
                <span>{item.description}</span>
            </div>
            {
                item.laundry && item.laundry.noLaundrySelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        keine wäscherei
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        0.00 CHF
                    </span>
                </div>
            }
            {
                item.laundry && item.laundry.washingAndIroningSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        {item.laundry.cleaningAndRefreshing ? "REINIGEN UND AUFFRISCHEN" : "Waschen & Bügeln"}
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.itemQuantity} x ${formatNumber(item.laundry.washingAndIroningPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.laundry && item.laundry.washingAndIroningSpeedySelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        waschen & bügeln express
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.itemQuantity} x ${formatNumber(item.laundry.washingAndIroningSpeedyPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.tailoring && item.tailoring.buttonSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        knopf
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.tailoring.buttonQuantity} x ${formatNumber(item.tailoring.buttonPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.tailoring && item.tailoring.hemSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        saum
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.tailoring.hemQuantity} x ${formatNumber(item.tailoring.hemPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.tailoring && item.tailoring.zipperSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        Reissverschluss
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.tailoring.zipperQuantity} x ${formatNumber(item.tailoring.zipperPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.delivery && item.delivery.packedSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        gefaltet
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.itemQuantity} x ${formatNumber(item.delivery.packedPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.delivery && item.delivery.hangerSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        auf Bügel
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.itemQuantity} x ${formatNumber(item.delivery.hangerPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.additional && item.additional.decorationSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        MIT DEKO
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.itemQuantity} x ${formatNumber(item.additional.decorationPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.additional && item.additional.sequinedSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        MIT STRASS, PAILETTEN
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.itemQuantity} x ${formatNumber(item.additional.sequinedPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.leather && item.leather.napaSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        NAPPA - UND NAPPALAN
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.itemQuantity} x ${formatNumber(item.leather.napaPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.leather && item.leather.velourSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        VELOUR
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.itemQuantity} x ${formatNumber(item.leather.velourPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.giftCard &&
                <div className="subtotal-item">
                </div>
            }
            <div>
                <span>
                    Menge: {item.itemQuantity}
                </span>
                <span className="pull-right">
                    Zwischentotal: {formatNumber(item.sumPrice)} CHF
                </span>
            </div>
            <hr />
        </div>
    );
};
export default SubtotalItem;

import React, { Component } from 'react'
import { Form as FinalForm, Field } from 'react-final-form'
import { Form, FormGroup, Row, Col, Label, Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { TextField, Radio, Select, } from 'final-form-material-ui';
import { connect } from 'react-redux';
import _ from 'lodash'
import {
    RadioGroup,
    FormControl,
    FormControlLabel,
    MenuItem
} from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { FORM_ERROR } from "final-form";
import * as actions from '../../store/actions'
import { place } from '../../Utils'
import Agb from '../Agb'

const FaceIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M9 11.75c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zm6 0c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8 0-.29.02-.58.05-.86 2.36-1.05 4.23-2.98 5.21-5.37C11.07 8.33 14.05 10 17.42 10c.78 0 1.53-.09 2.25-.26.21.71.33 1.47.33 2.26 0 4.41-3.59 8-8 8z" />
        </SvgIcon>
    );
}

const validate = values => {
    const errors = {};

    if (!values.email) {
        errors.email = "Die E-Mail ist erforderlich"
    } else {

        // eslint-disable-next-line no-useless-escape, no-control-regex
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

        if (!expression.test(String(values.email).toLowerCase())) {
            errors.email = "Nicht gültige E-Mail-Adresse"
        }
    }

    if (!values.firstName) {
        errors.firstName = "Der Vorname ist erforderlich"
    }
    if (!values.lastName) {
        errors.lastName = "Der Nachname ist erforderlich"
    }
    if (!values.password) {
        errors.password = "Passwort ist erforderlich"
    } else if (values.password.length < 5) {
        errors.password = "Passwort Mindestlänge 5 Zeichen"
    }
    if (!values.passwordCheck) {
        errors.passwordCheck = "Wiederholtes Passwort ist erforderlich"
    }
    if (values.password && values.passwordCheck) {
        if (values.password !== values.passwordCheck) {
            errors.passwordCheck = "Die eingegebenen Passwörter stimmen nicht überein"
        }
    }
    if (!values.gender) {
        errors.gender = "Geschlecht ist erforderlich"
    }
    if (!values.locationName) {
        errors.locationName = "Abholort ist erforderlich"
    }
    if (!values.street) {
        errors.street = "Strasse und Hausnummer ist erforderlich"
    }
    if (!values.phone) {
        errors.phone = "Telefon ist erforderlich"
    }
    if (!values.city) {
        errors.city = "PLZ/Ort ist erforderlich"
    }

    return errors;
}

class Register extends Component {

    state = {
        modalShowed: false
    }

    onSubmit = async (values) => {
        const { OnRegister } = this.props
        try {
            await new Promise((resolve, reject) => {
                OnRegister(_.pick(values,
                    [
                        'firstName',
                        'lastName',
                        'email',
                        'street',
                        'password',
                        'company',
                        'gender',
                        'city',
                        'phone',
                        'note',
                        'locationName'
                    ]), resolve, reject);
            });
        } catch (error) {
            switch (error) {
                case 1:
                    return { [FORM_ERROR]: "E-Mail existiert bereits" };
                default:
                    return { [FORM_ERROR]: "Etwas ist schief gelaufen, bitte versuche es später oder kontaktiere uns" };
            }
        }
    }


    render() {

        const { OnModalToggle } = this.props
        const { modalShowed } = this.state

        return (
            <React.Fragment>
                <Modal isOpen={modalShowed} toggle={() => this.setState({ modalShowed: !modalShowed })} centered size="xl">
                    <ModalHeader toggle={() => this.setState({ modalShowed: !modalShowed })}>AGB</ModalHeader>
                    <ModalBody>
                        <Agb />
                    </ModalBody>
                </Modal>
                <h4 className="pl-2">Registrieren</h4>
                <FinalForm
                    onSubmit={this.onSubmit}
                    validate={validate}
                    subscription={{ submitting: true, pristine: true, dirtySinceLastSubmit: true, submitSucceeded: true, submitError: true, submitErrors: true }}
                    render={({
                        handleSubmit,
                        submitting,
                        pristine,
                        validating,
                        hasValidationErrors,
                        submitError
                    }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col className="pt-2 pl-4 pr-4" lg="6">
                                        <h5 className="modal-small-header">Persönliche Daten</h5>
                                        <FormGroup>
                                            <Field
                                                fullWidth
                                                name="company"
                                                component={TextField}
                                                type="text"
                                                placeholder="Firmenname"
                                                label="Firmenname"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Field
                                                fullWidth
                                                required
                                                name="firstName"
                                                component={TextField}
                                                type="text"
                                                placeholder="Vorname"
                                                label="Vorname"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Field
                                                fullWidth
                                                required
                                                name="lastName"
                                                component={TextField}
                                                type="text"
                                                placeholder="Nachname"
                                                label="Nachname"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Field
                                                fullWidth
                                                name="email"
                                                component={TextField}
                                                type="text"
                                                placeholder="E-Mail *"
                                                label="E-Mail *"
                                                autoComplete="username"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Field
                                                fullWidth
                                                required
                                                name="password"
                                                component={TextField}
                                                type="password"
                                                placeholder="Passwort"
                                                label="Passwort"
                                                autoComplete="new-password"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Field
                                                fullWidth
                                                required
                                                name="passwordCheck"
                                                component={TextField}
                                                type="password"
                                                placeholder="Passwort wiederholen"
                                                label="Passwort wiederholen"
                                                autoComplete="new-password"
                                            />
                                        </FormGroup>
                                        <FormControl component="fieldset">
                                            <RadioGroup row>
                                                <FormControlLabel
                                                    label="männl."
                                                    control={
                                                        <Field
                                                            name="gender"
                                                            color="secondary"
                                                            component={Radio}
                                                            type="radio"
                                                            value="1"
                                                        />
                                                    }
                                                />
                                                <FormControlLabel
                                                    label="weibl."
                                                    control={
                                                        <Field
                                                            name="gender"
                                                            component={Radio}
                                                            type="radio"
                                                            value="2"
                                                        />
                                                    }
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Col>
                                    <Col className="pt-2 pl-4 pr-4" lg="6">
                                        <h5 className="modal-small-header">Adresse</h5>
                                        <FormGroup>
                                            <Field
                                                fullWidth
                                                required
                                                name="locationName"
                                                component={TextField}
                                                type="text"
                                                placeholder="Ort der Abholung (z.B zu Hause, im Büro ...)"
                                                label="Ort der Abholung (z.B zu Hause, im Büro ...)"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Field
                                                fullWidth
                                                required
                                                name="street"
                                                component={TextField}
                                                type="text"
                                                placeholder="Strasse und Hausnummer"
                                                label="Strasse und Hausnummer"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Field
                                                name="city"
                                                required
                                                label="PLZ/Ort *"
                                                formControlProps={{ fullWidth: true }}
                                                component={Select}
                                                placeholder="PLZ/Ort *"
                                            >
                                                {
                                                    place.map((item, index) => (<MenuItem key={index} value={`${item.postcode} ${item.place}`}>{`${item.postcode} ${item.place}`}</MenuItem>))
                                                }
                                            </Field>
                                        </FormGroup>
                                        <FormGroup>
                                            <Field
                                                fullWidth
                                                required
                                                name="phone"
                                                component={TextField}
                                                type="text"
                                                placeholder="Telefon"
                                                label="Telefon"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Field
                                                fullWidth
                                                name="note"
                                                component={TextField}
                                                type="text"
                                                placeholder="Anmerkungen"
                                                label="Anmerkungen"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="row m-0">
                                                <div className="col-md-auto" style={{ paddingTop: "10px", paddingRight: "5px", paddingLeft: "5px" }}><FaceIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} /></div>
                                                <Col className="register-form-italic-text"
                                                    style={{
                                                        paddingLeft: "5px",
                                                        lineHeight: "1.3"
                                                    }}>
                                                    Bitte nicht vergessen, in der Anmerkungen anzugeben: Treppe, Etage, Innenbereich und andere für die Abholung nützliche Informationen!
                                                </Col>
                                            </Label>
                                        </FormGroup>
                                        {submitError && <div className="error">{submitError}</div>}
                                    </Col>
                                </Row>
                                <div className="row d-flex flex-row">
                                    <Col className="col pl-4 pb-4 register-form-italic-text" lg="6">
                                        Mit Deiner Registrierung akzeptierst Du die
                                        <Button
                                            color="link"
                                            style={{ paddingLeft: "6px", paddingRight: "4px", marginBottom: "2px", color: "#2a4a9a" }}
                                            onClick={
                                                (e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    this.setState({ modalShowed: true })
                                                }}
                                        > AGB </Button>
                                        für die wös.ch Dienstleistungen.
                                    </Col>
                                    <Col lg="6" className="pl-4 pr-4">
                                        <div className="d-flex flex-row-reverse">
                                            <Button
                                                className="ml-auto"
                                                color="primary"
                                                type="submit"
                                                disabled={submitting || validating || pristine || hasValidationErrors}>
                                                {submitting ? "REGISTRIEREN..." : "REGISTRIEREN"}
                                            </Button>
                                            <Button
                                                color="secondary"
                                                type="button"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    OnModalToggle()
                                                }}>
                                                ABBRECHEN
                                            </Button>
                                        </div>
                                    </Col>
                                </div>
                            </Form>
                        )}
                />
            </React.Fragment>)
    }
}


const mapDispatchToProps = dispatch => {
    return {
        OnRegister: (values, resolve, reject) => dispatch(actions.register(values, resolve, reject)),
        OnModalToggle: () => dispatch(actions.authModalToggle())
    };
};

export default connect(null, mapDispatchToProps)(Register)
import { takeEvery, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import {
  checkAuthTimeoutSaga,
  authUserSaga,
  logoutSaga,
  authCheckStateSaga,
  discamerClickSaga,
  registerUserSaga,
  routingChangeSaga,
  setNewPasswordSaga,
  sendPasswordResetSaga,
  userUpdatedSaga
} from './auth';
import { AddToShoppingCartAndGoToCart, RemoveFromShoppingCartAndBackToProducts, RemoveTempFromShoppingCartAndBackToProducts, createPayEngineOrderIntent, applyCode } from './shoppingCart'
import { GetProducts } from './products'
import { GetAddresses, AddressSubmit, AddressDelete } from './addresses'
import { GetBonuses } from './bonuses'
import { SubmitOrder, GetOrders, GetAdminOrders, getAdminOrderDetails, orderStatusChange, getOrderDetails, SubmitOrderAndGoToDetails } from './orders'
import { GetAdminUsers, getAdminUserDetails } from './users'

export function* watchAuth() {
  yield takeEvery(actionTypes.AUTH_CHECK_TIMEOUT, checkAuthTimeoutSaga);
  yield takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, logoutSaga);
  yield takeEvery(actionTypes.AUTH_USER, authUserSaga);
  yield takeEvery(actionTypes.REGISTER_USER, registerUserSaga);
  yield takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga)
  yield takeEvery(actionTypes.AGB_CLICK, discamerClickSaga)
  yield takeEvery("@@router/LOCATION_CHANGE", routingChangeSaga)
  yield takeEvery(actionTypes.SET_NEW_PASSWORD, setNewPasswordSaga)
  yield takeEvery(actionTypes.SEND_PASSWORD_RESET, sendPasswordResetSaga)
  yield takeEvery(actionTypes.USER_UPDATED, userUpdatedSaga)
}

export function* watchShoppingCart() {
  yield takeEvery(actionTypes.ADD_TO_SHOPPING_CART_AND_GO_TO_CART, AddToShoppingCartAndGoToCart);
  yield takeEvery(actionTypes.REMOVE_FROM_SHOPPING_CART_AND_BACK_TO_PRODUCTS, RemoveFromShoppingCartAndBackToProducts)
  yield takeLatest(actionTypes.CREATE_PAYENGINE_ORDER_INTENT, createPayEngineOrderIntent)
  yield takeLatest(actionTypes.APPLY_CODE, applyCode)
}

export function* watchProducts() {
  yield takeEvery(actionTypes.GET_PRODUCTS, GetProducts);
}

export function* watchAddresses() {
  yield takeEvery(actionTypes.GET_ADDRESSES, GetAddresses);
  yield takeEvery(actionTypes.ADDRESS_SUBMIT, AddressSubmit);
  yield takeEvery(actionTypes.ADDRESS_DELETE, AddressDelete)
}

export function* watchBonuses() {
  yield takeEvery(actionTypes.GET_BONUSES, GetBonuses);
}

export function* watchOrders() {
  yield takeEvery(actionTypes.SUBMIT_ORDER_GO_TO_DETAILS, SubmitOrderAndGoToDetails);
  yield takeEvery(actionTypes.SUBMIT_ORDER, SubmitOrder);
  yield takeEvery(actionTypes.GET_ORDERS, GetOrders)
  yield takeEvery(actionTypes.GET_ADMIN_ORDERS, GetAdminOrders)
  yield takeEvery(actionTypes.GET_ADMIN_ORDER_DETAILS, getAdminOrderDetails)
  yield takeEvery(actionTypes.GET_ORDER_DETAILS, getOrderDetails)
  yield takeEvery(actionTypes.ORDER_STATUS_CHANGE, orderStatusChange)
}

export function* watchUsers() {
  yield takeEvery(actionTypes.GET_ADMIN_USERS, GetAdminUsers)
  yield takeEvery(actionTypes.GET_USER_DETAILS, getAdminUserDetails)
}
import React, { Component } from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { connect } from 'react-redux'
import { formatNumber } from '../../Utils'

import * as actions from '../../store/actions'

class VariantItem extends Component {

    render() {

        const {
            variants,
            itemIndex,
            variantSelected
        } = this.props

        

        return (
            <ListGroup flush className="shopping-item-section">
                <ListGroupItem className="item-main-header">
                    <strong>Variante</strong>
                </ListGroupItem>
                {
                    variants.map(variant =>
                        <ListGroupItem key={variant._id} className="item-button single" active={variantSelected == variant._id}
                            onClick={() => {
                                if (variantSelected != variant._id) {
                                    this.props.OnVariantCheck(itemIndex, variant._id)
                                }
                            }}>
                            <div className="d-flex item-header-font">
                                <div className="item-header">{variant.description ? variant.description : variant.name }</div>
                                <div className="small-price">
                                    {formatNumber(variant.sumPrice)}
                                </div>
                            </div>
                        </ListGroupItem>)
                }
            </ListGroup>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        OnVariantCheck: (itemIndex, variantId) => dispatch(actions.variantCheck(itemIndex, variantId))
    };
};

export default connect(null, mapDispatchToProps)(VariantItem)
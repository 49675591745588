import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router'
import { connect } from 'react-redux';
import * as actions from "../store/actions";

class Payengine extends Component {

    render() {

        const { location: { search }, removeAllProductsFromShoppingCart } = this.props

        const status = (new URLSearchParams(search)).get("status")
        const orderId = (new URLSearchParams(search)).get("order")

        switch (status) {
            case "success":

            removeAllProductsFromShoppingCart()

            if(orderId){
                return <Redirect to={`/profile/orders/${orderId}?success=true`} />
            } else {
                return <Redirect to="/products?success=true" />
            }

            //fail or cancel
            default:
                return <Redirect to="/shopping-cart/page2" />

        }
    }
}

const mapDispatchToProps = dispatch => ({
    removeAllProductsFromShoppingCart: () => dispatch(actions.removeAllProductsFromShoppingCart()),
});

export default withRouter(connect(null, mapDispatchToProps)(Payengine))
import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { formatNumber } from '../../Utils'

const PerKiloItem = (
    {
        washingAndIroningPerKiloPrice,
        washingPerKiloPrice
    }) => (
        <ListGroup flush className="shopping-item-section">
            <ListGroupItem className="item-main-header">
                <strong>Wäscherei</strong>
            </ListGroupItem>
            {
                washingAndIroningPerKiloPrice > 0 &&
                <ListGroupItem className="item-button single active">
                    <div className="d-flex item-header-font">
                        <div className="item-header">Waschen & Bügeln</div>
                        <div className="small-price">
                            {formatNumber(washingAndIroningPerKiloPrice)}
                        </div>
                    </div>
                </ListGroupItem>
            }
            {
                washingPerKiloPrice > 0 &&
                <ListGroupItem className="item-button active">
                    <div className="d-flex item-header-font">
                        <div className="item-header">Waschen, nicht gebügelt</div>
                        <div className="small-price">
                            {formatNumber(washingPerKiloPrice)}
                        </div>
                    </div>
                </ListGroupItem>
            }
        </ListGroup>
    )

export default PerKiloItem
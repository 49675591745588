import React, { Component } from 'react'
import { Form as FinalForm, Field } from 'react-final-form'
import { Form, FormGroup, Button } from 'reactstrap'
import { connect } from 'react-redux';
import { FORM_ERROR } from "final-form";
import { TextField } from 'final-form-material-ui';

import * as actions from '../../store/actions'

const validate = values => {
    const errors = {};

    if (!values.password) {
        errors.password = "Passwort ist erforderlich"
    } else if (values.password.length < 5) {
        errors.password = "Passwort Mindestlänge 5 Zeichen"
    }
    if (!values.passwordCheck) {
        errors.passwordCheck = "Wiederholtes Passwort ist erforderlich"
    }
    if (values.password && values.passwordCheck) {
        if (values.password !== values.passwordCheck) {
            errors.passwordCheck = "Die eingegebenen Passwörter stimmen nicht überein"
        }
    }

    return errors;
}


class NewPassword extends Component {

    onSubmit = async (values) => {
        const { OnSetNewPassword } = this.props
        try {
            await new Promise((resolve, reject) => {
                OnSetNewPassword(values.token, values.password, resolve, reject);
            });
        } catch (error) {
            switch (error) {
                case 1:
                    return { [FORM_ERROR]: "E-Mail nicht gefunden. Registriere dich, um darauf zugreifen zu können" };
                case 2:
                    return { [FORM_ERROR]: "Passwort und E-Mail stimmen nicht überein" };
                default:
                    return { [FORM_ERROR]: "Etwas ist schief gelaufen, bitte versuche es später oder kontaktiere uns" };
            }
        }
    }

    render() {

        const { token, OnNewPasswordModalToggle } = this.props

        return (
            <React.Fragment>
                <div style={{ height: "10px" }}></div>
                <FinalForm
                    onSubmit={this.onSubmit}
                    validate={validate}
                    initialValues={{ token: token }}
                    render={({
                        submitError,
                        handleSubmit,
                        submitting,
                        pristine,
                        validating,
                        hasValidationErrors
                    }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Field
                                        fullWidth
                                        required
                                        name="password"
                                        component={TextField}
                                        type="password"
                                        placeholder="Neues Passwort"
                                        label="Neues Passwort"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Field
                                        fullWidth
                                        required
                                        name="passwordCheck"
                                        component={TextField}
                                        type="password"
                                        placeholder="Passwort wiederholen"
                                        label="Passwort wiederholen"
                                    />
                                </FormGroup>
                                {submitError && <div className="error">{submitError}</div>}
                                <br />
                                <div className="row justify-content-between">
                                    <Button
                                        className="ml-3"
                                        color="secondary"
                                        type="button"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            OnNewPasswordModalToggle()
                                        }}>
                                        ABBRECHEN
                                            </Button>
                                    <Button
                                        className="mr-3"
                                        color="primary"
                                        type="submit"
                                        disabled={submitting || validating || pristine || hasValidationErrors}>
                                        {submitting ? "BESTÄTIGEN..." : "BESTÄTIGEN"}
                                    </Button>
                                </div>
                            </Form >
                        )
                    }
                />
            </React.Fragment>)
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.auth.passwordResetToken,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        OnSetNewPassword: (token, password, resolve, reject) => dispatch(actions.setNewPassword(token, password, resolve, reject)),
        OnNewPasswordModalToggle: () => dispatch(actions.newPasswordModalToggle())
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(NewPassword)
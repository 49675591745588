import React from 'react'

export default ({ locationName, street, city, phone, note, updateFromToDateTime, date, time, editVisible, editClick }) => (

    <React.Fragment>
        <div className="address" onClick={editVisible ? editClick : null}>
            <i className=" fas fa-map-marker-alt"></i>
            <div className="checkout-step-box-content">
                <div>{locationName}</div>
                <div>{street}</div>
                <div>{city}</div>
            </div>
            {editVisible &&
                <img alt="" className="checkout-page-address-edit-btn float-right" style={{ cursor: "pointer" }} src={require("../../img/checkoutPage/pen_icon.png")} onClick={editClick} />
            }
        </div>
        <div className="address" onClick={editVisible ? editClick : null}>
            <i className="fas fa-phone fa-rotate-90"></i>
            <div className="checkout-step-box-content">
                <div>{phone ? phone : ' '}</div>
            </div>
            {editVisible &&
                <img alt="" className="checkout-page-address-edit-btn float-right" style={{ cursor: "pointer" }} src={require("../../img/checkoutPage/pen_icon.png")} onClick={editClick} />
            }
        </div>
        <div className="address" onClick={editVisible ? editClick : null}>
            <i className="far fa-comment-alt"></i>
            <div className="checkout-step-box-content">
                <div>{note}</div>
            </div>
            {editVisible &&
                <img alt="" className="checkout-page-address-edit-btn float-right" style={{ cursor: "pointer" }} src={require("../../img/checkoutPage/pen_icon.png")} onClick={editClick} />
            }
        </div>
        <div className="address from-to-date-time" onClick={editVisible ? updateFromToDateTime : null}>
            <i className="fas fa-calendar-alt"></i>
            <div className="checkout-step-box-content">
                <div>{date}</div>
            </div>
            <br />
            <i className="far fa-clock"></i>
            <div className="checkout-step-box-content">
                <div>{time}</div>
            </div>
            {editVisible &&
            <img alt="" className="checkout-page-address-edit-btn float-right" style={{ cursor: "pointer" }} src={require("../../img/checkoutPage/pen_icon.png")} onClick={updateFromToDateTime} />
            }
        </div>
    </React.Fragment>
)
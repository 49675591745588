import React, { Component } from 'react'
import { Row, Col, UncontrolledPopover, PopoverHeader, PopoverBody, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import * as actions from '../../store/actions'
import { getItemByIndex, getItemsCount } from '../../store/reducers/shoppingCart';
import { formatNumber } from '../../Utils'
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import LeatherItem from './LeatherItem'
import LanudryItem from './LaundryItem'
import VariantItem from './VariantItem'
import TailoringItem from './TailoringItem'
import DeliveryItem from './DeliveryItem'
import AdditionalItem from './AdditionalItem';
import SvgClose from '../shared/SvgClose'
import SvgArrow from '../shared/SvgArrow'
import SvgTick from '../shared/SvgTick'
import QuantityField from './QuantityField'
import PerKiloItem from './PerKiloItem';
import LongProcessingIcon from '../shared/LongProcessingIcon';
import GiftCardItem from './GiftCardItem';
import { Divider } from '@material-ui/core';


class CartItem extends Component {

    state = {
        notes: this.props.newItemOverlayProduct ? this.props.newItemOverlayProduct.notes : this.props.item.notes,
        isOpen: false
    }

    notesChange = (e) => {
        this.setState({ notes: e.target.value })
    }

    isWide(item) {
        let checks = 0;
        if (item.additional) checks++;
        if (item.tailoring) checks++;
        if (this.props.newItemOverlayVariants && this.props.newItemOverlayVariants.length>1) checks++;
        return checks>1;
    } 

    elemClass(item) {
        let checks = 0;
        if (this.props.newItemOverlayVariants && this.props.newItemOverlayVariants.length>1) checks++;
        if (item.laundry) checks++;
        if (item.tailoring) checks++;
        if (item.additional) checks++;
        if (item.delivery) checks++;
        return checks;
    }

    render() {

        const {
            itemIndex,
            itemsCount,
            OnRemoveFromShoppingCart,
            OnRemoveTempFromShoppingCart,
            OnQuantityAdd,
            OnQuantityRemove,
            AddToShoppingCart,
            temp,
            added,
            newItemOverlayProduct
        } = this.props

        

        const { isOpen } = this.state
        let item;
        if(newItemOverlayProduct) {
            item = newItemOverlayProduct
        } else {
            item = this.props.item
        }

        return (
            <React.Fragment>
                <Modal isOpen={isOpen} toggle={() => this.setState(state => ({ isOpen: !state.isOpen, notes: item.notes }))} centered size="sm">
                    <ModalHeader toggle={() => this.setState(state => ({ isOpen: !state.isOpen, notes: item.notes }))}>Hinweis</ModalHeader>
                    <ModalBody>
                        <TextField onChange={(e) => this.setState({ notes: e.target.value })} type="search" multiline rowsMax="5"
                            id="standard-basic"
                            value={this.state.notes}
                            style={{ width: "265px", paddingBottom: "20px" }} />
                        <div className="row justify-content-between">
                            <Button
                                className="ml-3"
                                color="secondary"
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    this.setState(state => ({ isOpen: !state.isOpen, notes: item.notes }))
                                }}>
                                ABBRECHEN
                                </Button>
                            <Button
                                className="mr-3"
                                color="primary"
                                type="submit"
                                onClick={() => {
                                    this.props.OnNotesChange(itemIndex, this.state.notes)
                                    this.setState(state => ({ isOpen: !state.isOpen }))
                                }}
                                disabled={!(item.notes !== this.state.notes)}>
                                BESTÄTIGEN
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
                <div className={`cart-config ${temp ? 'temp' : ''} ${added ? 'added' : ''} ${this.isWide(item) ? 'wide' : ''} col--${this.elemClass(item)}`}>
                    <div className="cart-config-inner">
                        {
                                added &&
                                <div className="cart-config-header">
                                    <div className="left"><SvgTick /></div>
                                    <div className="right">
                                        <div className="cart-config-header-top bold">Produkt hinzugefügt.</div>
                                        <div className="cart-config-header-bottom">Hier kannst du deinen Artikel konfigurieren.</div>
                                    </div>
                                    <button
                                className="close-overlay"
                                onClick={() =>  OnRemoveTempFromShoppingCart(itemIndex, itemsCount)}>
                                <SvgClose />
                            </button>
                                </div>
                            }
                            {
                                temp &&
                                <div className="cart-config-header">
                                    <div className="right">
                                        <div className="cart-config-header-top bold">Produkt konfigurieren</div>
                                        <div className="cart-config-header-bottom">Wähle deine gewünschte Behandlung</div>
                                    </div>
                                    <button
                                className="close-overlay"
                                onClick={() =>  OnRemoveTempFromShoppingCart(itemIndex, itemsCount)}>
                                <SvgClose />
                            </button>
                                </div>
                            }
                        <div className="cart-content">
                        <Row className="justify-content-md-center cart-item" style={{ marginBottom: "20px" }}>
                            <Col lg className="image-price-container">
                            {
                                !temp &&
                                <button
                                    className="close-btn-item close-btn-item-lg ripple"
                                    onClick={() => OnRemoveFromShoppingCart(itemIndex, itemsCount)}>
                                    <SvgClose />
                                </button>
                            }
                                <img src={item.imgUrl}
                                    alt="product"
                                    className="image-price-container-image" />
                                <div style={{ width: "100%" }}>
                                    <div>
                                        <div style={{ textTransform: "uppercase", fontWeight: "600" }}>{item.name}
                                            {
                                                item.laundry &&
                                                item.laundry.perKilo === true &&
                                                <React.Fragment>
                                                    <FontAwesomeIcon className="ml-1" style={{ color: "#2a4a91" }} icon={faInfoCircle} id={`id_item_desc_${itemIndex}`} />
                                                    <UncontrolledPopover trigger="hover" placement="right" target={`id_item_desc_${itemIndex}`}>
                                                        <PopoverHeader>Beinhaltet nur folgende Artikel</PopoverHeader>
                                                        <PopoverBody>Badetuch, Geschirr- und Küchenhandtuch, Unterhemd, Unterwäsche, Socken, Strumpfhose, Pyjama, Jeanshosen, T-Shirt, Polo Shirt, Trainingsjacke und Trainingshose.</PopoverBody>
                                                    </UncontrolledPopover>
                                                </React.Fragment>
                                            }
                                        </div>
                                        <div style={{ paddingTop: "10px" }}>{item.description}</div>
                                    </div>
                                    <div>
                                        { /*
                                            REMOVE FOR NOW -> LATER WE USE SEPARATE DELIVERY DATES
                                            item.laundry &&
                                            !item.laundry.washingAndIroningSpeedy &&
                                            !item.giftCard &&
                                            <LongProcessingIcon longProcessing={item.longProcessing} />
                                        
                                        */ } 
                                        <div className="quantity-price-box">
                                            <div className="quantity-price-part">
                                                <QuantityField
                                                    lg
                                                    quantity={item.itemQuantity}
                                                    add={() => OnQuantityAdd(itemIndex, "item")}
                                                    remove={() => OnQuantityRemove(itemIndex, "item")}
                                                    quantityType={item.quantityType}
                                                    perKilo={item.laundry ? item.laundry.perKilo : false}
                                                    itemIndex={itemIndex} />
                                            </div>
                                            <div className="price-cart-item price-cart-item-left">
                                                {formatNumber(item.sumPrice)}
                                                <span className="ml-1" style={{ fontSize: "12px" }}>&#67;&#72;&#70;</span>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <Button size="sm" className="note-btn-item-lg" onClick={() => this.setState(state => ({ isOpen: !state.isOpen }))}
                                        style={{
                                        }}>
                                        <img className="mr-1" alt="" src={require('../../img/checkoutPage/hinweis_icon.png')} style={{ width: "24px", height: "24px" }} />
                                        <span style={{ paddingTop: "6px", verticalAlign: "middle", color: "#7E8080" }}>HINWEIS</span>
                                    </Button>
                                </div>
                               
                            </Col>
                            {
                                item.giftCard && <GiftCardItem itemIndex={itemIndex} {...item.giftCard} />
                            }
                            {
                                temp && this.props.newItemOverlayVariants.length > 1 && 
                                <Col lg>
                                    <VariantItem itemIndex={itemIndex} variants={this.props.newItemOverlayVariants} variantSelected={item._id} />
                                </Col>
                            }
                            {
                                !item.giftCard &&
                                <>
                                    <Col lg className="laundry">
                                        {
                                            item.leather &&
                                            <LeatherItem itemIndex={itemIndex} {...item.leather} />
                                        }
                                        {
                                            item.laundry &&
                                            item.laundry.perKilo &&
                                            <PerKiloItem itemIndex={itemIndex} {...item.laundry} />
                                        }
                                        {
                                            item.laundry &&
                                            !item.laundry.perKilo &&
                                            <LanudryItem itemIndex={itemIndex} {...item.laundry} />
                                        }
                                    </Col>
                                    <Col lg>
                                        {
                                            item.tailoring &&
                                            <TailoringItem itemIndex={itemIndex} {...item.tailoring} />
                                        }
                                    </Col>
                                    <Col lg>
                                        {
                                            item.delivery &&
                                            <DeliveryItem itemIndex={itemIndex} {...item.delivery} />
                                        }
                                    </Col>
                                </>
                            }
                            {
                                temp && item.additional && 
                                <Col lg>
                                    <AdditionalItem itemIndex={itemIndex}  {...item.additional} />
                                </Col>
                            }
                            <Col lg className="price-section">
                                <div className="price-cart-item price-cart-item-right">
                                    {formatNumber(item.sumPrice)}
                                    <span className="ml-1" style={{ fontSize: "12px" }}>&#67;&#72;&#70;</span></div>
                                
                                    {
                                !temp &&
                                <button
                                    className="close-btn-item close-btn-item-sm ripple"
                                    onClick={() => OnRemoveFromShoppingCart(itemIndex, itemsCount)}>
                                    <SvgClose />
                                </button>
                                }
                                
                                <Button size="sm" className="note-btn-item-sm" onClick={() => this.setState(state => ({ isOpen: !state.isOpen }))}
                                    style={{
                                    }}>
                                    <img className="mr-1" alt="" src={require('../../img/checkoutPage/hinweis_icon.png')} style={{ width: "24px", height: "24px" }} />
                                    <span style={{ paddingTop: "6px", verticalAlign: "middle", color: "#7E8080" }}>HINWEIS</span>
                                </Button>
                            </Col>
                            
                            {
                            added && 
                                
                            <div className="overlay-actions">
                                <a href="#" className="add-more overlay-button bold" onClick={(e) =>  { e.preventDefault(); OnRemoveTempFromShoppingCart(itemIndex, itemsCount)}}>
                                +  Weitere Artikel hinzufügen</a>
                            <Link to="/shopping-cart/page1" className="to-basket overlay-button bold">
                                    Weiter zum Wäschekorb <SvgArrow />                            
                                </Link>
                                
                                
                            </div>
                        }
                        {
                            temp && 
                                
                            <div className="overlay-actions">
                                <a href="#" className="ml-auto add-more overlay-button bold" onClick={(e) =>  { 
                                    e.preventDefault(); 
                                    AddToShoppingCart();
                                    if(window._paq) window._paq.push(['trackEvent', 'Products', 'Add To Basket', item.name, item._id]);
                                }}>
                                Hinzufügen zum Wäschekorb<span className="hidden-desktop">&nbsp;• {formatNumber(item.sumPrice)}</span></a>     
                            </div>
                        }
                        </Row>
                        </div>
                    </div>
                </div>

                {/* <Row className="justify-content-md-center cart-item" style={{ marginBottom: "20px" }}>
                    <Col lg className="image-price-container" style={{ minHeight: "unset" }}>
                    </Col>
                    <Col>
                        <Row className="d-flex justify-content-end" style={{ paddingRight: "15px", marginBottom: "15px" }}>
                            <TextField onChange={(e) => this.setState({ notes: e.target.value })} type="search" multiline rowsMax="4"
                                id="standard-basic"
                                label="Hinweis"
                                value={this.state.notes}
                                style={{ width: "300px" }} />
                            {
                                (item.notes !== this.state.notes) && <Button
                                    title="Speichern"
                                    color="link"
                                    size="sm"
                                    style={{ height: "32px", marginTop: "16px", color: "#2a4a9a" }}
                                    onClick={() => this.props.OnNotesChange(itemIndex, this.state.notes)}
                                >
                                    <FontAwesomeIcon size="sm" icon={faCheck} /></Button>
                            }
                            {
                                this.state.notes && <Button
                                    title="Klar"
                                    color="link"
                                    size="sm"
                                    style={{ height: "32px", marginTop: "16px", color: "#2a4a9a" }}
                                    onClick={() => {
                                        this.setState({ notes: "" })
                                        this.props.OnNotesChange(itemIndex, "")
                                    }}
                                >
                                    <FontAwesomeIcon size="sm" icon={faTimes} /></Button>
                            }
                        </Row>
                    </Col>
                    <Col lg className="price-section">
                    </Col>
                </Row> */}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    

    return {
        item: getItemByIndex(state, ownProps.itemIndex),
        itemsCount: getItemsCount(state),
        newItemOverlayVariants: state.shoppingCart.newItemOverlayVariants,
        newItemOverlayProduct: state.shoppingCart.newItemOverlayProduct
    };
};

const mapDispatchToProps = dispatch => {
    return {
        OnRemoveFromShoppingCart: (itemIndex, itemsCount) => dispatch(actions.removeProductFromShoppongCartAndBackToProducts(itemIndex, itemsCount)),
        OnRemoveTempFromShoppingCart: (itemIndex, itemsCount) => dispatch(actions.removeTempProductFromShoppongCart(itemIndex)),
        OnQuantityAdd: (itemIndex, itemType) => dispatch(actions.quantityAdd(itemIndex, itemType)),
        OnQuantityRemove: (itemIndex, itemType) => dispatch(actions.quantityRemove(itemIndex, itemType)),
        OnNotesChange: (itemIndex, text) => dispatch(actions.notesChange(itemIndex, text)),
        AddToShoppingCart: () => dispatch(actions.AddToShoppingCart()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(CartItem)